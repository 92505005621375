<template>
  <div class="bg-default">
    <nav class="pa-3 d-flex justify-space-between">
      <router-link to="assignment" class="deco">
        <v-btn dark fab small color="indigo">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
      <div class="pa-2">
        <v-btn @click="switchMode('light')" color="black" dark v-if="dark"
          ><v-icon>mdi-weather-night</v-icon>dark mode</v-btn
        >
        <v-btn @click="switchMode('dark')" color="blue" dark v-if="!dark"
          ><v-icon>mdi-white-balance-sunny</v-icon> Light mode</v-btn
        >
      </div>
    </nav>
    <v-card class="ma-3">
      <div :class="dark ? 'blackest' : 'whiteness'">
        <v-toolbar color="indigo" dense dark><h3>Narasi</h3></v-toolbar>
        <article class="pa-5" v-if="fase == 'Individu'">
          <span v-html="module.narasi_individu"></span>
        </article>
        <article class="pa-5" v-if="fase == 'Kelompok'">
          <span v-html="module.narasi_kelompok"></span>
        </article>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "narasi",
  computed: {
    ...mapState(["module"])
  },
  data() {
    return {
      fase: localStorage.getItem("fase"),
      dark: false
    };
  },
  methods: {
    switchMode(mode) {
      if (mode == "dark") {
        this.dark = true;
      } else if (mode == "light") {
        this.dark = false;
      }
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
}
.deco {
  text-decoration: none;
}
.blackest {
  background: #121212;
  transition: 0.5s;
  color: #fff;
}
.whiteness {
  background: #fff;
  color: #000;
  transition: 0.5s;
}
</style>
